<template>
  <div class="box-tab-date">
    <p class="label">{{qrTitle}}</p>
    <p v-if="q.date_type == '自定义'" class="range">
      <el-date-picker
        v-model="q.start_date"
        type="date"
        align="center"
        prefix-icon=" "
        value-format="yyyy-MM-dd"
        :picker-options="startOpts"
        format="yy.M.dd"
        @change="selStartDate"
        placeholder="开始日期">
      </el-date-picker>
      <span>-</span>
      <el-date-picker
        v-model="q.end_date"
        type="date"
        align="center"
        prefix-icon=" "
        value-format="yyyy-MM-dd"
        format="yy.M.dd"
        :picker-options="endOpts"
        @change="selEndDate"
        placeholder="结束日期">
      </el-date-picker>
    </p>
    <p class="type">
      <img v-if="false"
      class="icon" src="@/assets/i_date.png"/>
      <img v-else class="icon" src="@/assets/i_date_sel.png"/>
    <el-select v-model="q.date_type" placeholder="请选择" @change="selDateType">
      <el-option
        v-for="item in dateType"
        :key="item.value"
        :label="item"
        :value="item">
      </el-option>
      </el-select>
    </p>
  </div>
</template>
<script>
	export default {
		name: "data-search",
		props: {
			qrTitle: {
				type: String,
				required: false,
				default: '数据平台',
			},
			qrName: {
				type: String,
				required: false,
				value: 'dq',
			},
			dateQuery: {
				type: Object,
				required: false,
				value: {
					date_type: '',
					start_date: '',
					end_date: '',
				}
			},
		},
		data() {
			return {
				dateType: ['今日', '本周', '本月', '上月', '自定义'],
				isCustom: false,
        startOpts: {
          disabledDate: (time) => {
              return new Date(time) > new Date(this.end_date || '')
          }
        },
        endOpts: {
          disabledDate: (time) => {
              return new Date(time) < new Date(this.start_date || '')
          }
        },
				q: {
					date_type: '今天',
					start_date: '',
					end_date: '',
				}
			};
		},
		mounted() {
			this.q = {
				...this.q,
				...this.dateQuery
			}
		},
		beforeDestroy() {

		},
		watch: {
			src(newVal, oldVal) {

			}
		},
		computed: {

		},
		methods: {
			selStartDate() {
				this.emitData();
			},
			selEndDate() {
				this.emitData();
			},
			selDateType(v) {
				this.q = {
					...this.q,
					start_date: '',
					end_date: '',
					date_type: v
				}
				this.isCustom = v == '自定义';
				if (!this.isCustom) {
          this.emitData();
				}
			},
			emitData() {
				this.$emit('change', {
          ...this.q,
				});
			}
		}
	}
</script>
<style lang="less">
.box-tab-date {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  border-radius: 0.5rem;
  height: 2rem;
  padding-left: 0.5rem;

  .el-select .el-input {
    max-width: 3.2rem;
  }
  .input-with-select .el-input-group__prepend, .el-input-group__append {
    background-color: transparent;
  }

  .label {
    color: #377EEA;
    font-size: 0.7rem;
    font-weight: 500;
  }
  >p {
    font-size: 0.8rem;
    margin: 0;

    .el-input__inner {
      border: 0;
      padding: 0;
      font-size: 0.65rem;
      font-weight: 500;
      background-color: transparent;
    }
    .el-select {
      .el-input__inner, .el-select__caret {
        color: #377EEA;
      }
      .el-icon-arrow-up:before {
          content: "";
      }
    }
    .icon {
      height: 0.8rem;
      margin-right: 0.5rem;
    }
  }

  .type {
    display: flex;
    align-items: center;
  }
  .range {
    max-width: 40%;
    display: flex;
    align-items: center;
    .el-input__inner {
      text-align: center;
    }
  }
}
</style>
